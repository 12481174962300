import React from "react"
import { graphql, Link } from "gatsby"
import Posts from "../components/postList"
import Seo from "../components/seoPage"
import Hero from "../components/hero"
export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, numPages2 } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages2
    const prevPage = currentPage - 1 === 1 ? "" : currentPage - 1
    const nextPage = currentPage + 1
    return (
      <main>
        <Seo
          title="Resep masakan | Hajebo blog yang membahas dan berbagi resep masakan"
          diskripsi="Kategori hajebo blog yang membahas tentang resep masakan baik masakan indonesia maupun barat. Kami berusaha mebahas sedetail mungkin agara nadabisa menggunakan resep tanpa gagal"
          slug="/resep/"
          tags="resep masakan"
          imageUrl="/logo.png"
        />
        {!isFirst || (
          <Hero
            title="Resep Topics"
            sub="Ayo, masak dan cintai diri kamu dengan makanan yang lezat"
          />
        )}
        {!isFirst && (
          <div className="mx-auto mt-10 text-center">
            <h1 className="md:text-3xl border-blue-200 border-b-4 inline font-serif font-semibold mt-10 ">
              Resep Blog Page {currentPage}
            </h1>
          </div>
        )}
        <div className="flex flex-wrap flex-col md:flex-row lg:flex-row mx-4 md:mx-8 lg:mx-20 my-8 md:my-14 lg:my-14">
          {posts.map(({ node }) => (
            <Posts
              title={node.frontmatter.title}
              link={node.fields.slug}
              gambar={node.frontmatter.gambar.childImageSharp.gatsbyImageData}
              tags={node.frontmatter.tags}
              kategori={node.frontmatter.kategori}
              time={node.timeToRead}
              diskripsi={node.frontmatter.diskripsi}
            />
          ))}
        </div>
        <div className="m-4 flex flex-col">
          {!isFirst && (
            <Link to={`/resep/${prevPage}`} rel="prev">
              <button className="mx-4 bg-green-100 text-green-500 border-2 rounded-lg border-green-300 py-1 px-2 ">
                {" "}
                ← Previous Page
              </button>
            </Link>
          )}
          {!isLast && (
            <Link to={`/resep/${nextPage}`} rel="next">
              <button className="mx-4 float-right bg-indigo-100 text-indigo-500 border-2 rounded-lg border-indigo-300 py-1 px-2 ">
                Next Page →
              </button>
            </Link>
          )}
        </div>
      </main>
    )
  }
}

export const blogListQueryPeliharaan = graphql`
  query blogListQueryResep($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "./content/resep/" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            diskripsi
            title
            kategori
            gambar {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            tags
          }
        }
      }
    }
  }
`
